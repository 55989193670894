import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
} from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";

export default function About({ color }) {
  const profile = ProfileArray();
    return (
      <>
        <Container maxW={"3xl"} id="about">
          <Stack
            as={Box}
            textAlign={"center"}
            spacing={{ base: 8, md: 14 }}
            pb={{ base: 20, md: 20 }}
          >
            <Stack align="center" direction="row" px={4}>
              <HStack mx={4}>
                <Text color={`${color}.400`} fontWeight={800}>
                  01
                </Text>
                <Text fontWeight={800}>Intro</Text>
              </HStack>
              <Divider orientation="horizontal" />
            </Stack>
            <Text align="left" color={"{$color}.600"} fontSize={"xl"} px={4}>
              I define myself with three keywords..
              <br></br><br></br>
              {profile.about}
              <br></br><br></br>
              What do I really care about? Technology that make us more connected and free despite any physical distance, cultural difference, or language preference.
            </Text>
          </Stack>
        </Container>
      </>
    );
}

