import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Center,
  List,
  ListItem,
  ListIcon,
  Link
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/accordion"
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";
import ProfileArray from "./ProfileArray";
import { ChevronRightIcon } from "@chakra-ui/icons";

export default function Contact({ color }) {
  const profile = ProfileArray();
  const linkedin = () => {
    window.open(`${profile.linkedin}`, "_blank", "noreferrer,noopener");
  };
  const github = () => {
    window.open(`${profile.github}`, "_blank", "noreferrer,noopener");
  };
  const email = () => {
    window.open(`mailto:${profile.email}`, "_blank", "noreferrer,noopener");
  };
  return (
    <>
      <Container maxW={"3xl"} id="contact">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 2, md: 5 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                04
              </Text>
              <Text fontWeight={800}>Contact</Text>
            </HStack>
            <Divider orientation="horizontal" />
            
          </Stack>
          
          <Stack spacing={8} as={Container} maxW={"3xl"} textAlign={"center"}>
            <Text color={"white.600"} fontsize={"md"} as='b' px={4}>
            Interested in TONOMUS/NEOM?
            </Text>
            <Text color={"white.600"} fontSize={"md"} px={4}>
             Please use the company channels for partnership and business collaborations.
            </Text>
            <Text color={"white.600"} fontsize={"md"} as='b' px={4}>
            Interested in my experiences and life journey?
            </Text>
            <Text color={"white.600"} fontSize={"md"} align="left" px={4}>
            {profile.contact}
            </Text>
            <Text>
            <List align="left" spacing={4}>
                <ListItem>
                  <ListIcon
                    boxSize={6}
                    as={ChevronRightIcon}
                    color={`${color}.500`}
                  />
                  Donate on behalf of someone you are grateful for and love through <Link color="teal.500" target="#" href="https://ehsan.sa">Ehsan Platform</Link>
                </ListItem>

                <ListItem>
                  <ListIcon
                    boxSize={6}
                    as={ChevronRightIcon}
                    color={`${color}.500`}
                  />
                  Fill out the <Link target="#" color="teal.500" href="https://forms.gle/rUwZn6vMsqJk3ocr6">30 mins meeting form</Link> and attach the proof of donation.
                </ListItem>

            </List>
            </Text>
            <Text color={"white.600"} fontSize={"md"} align="left" px={4}>
            Alternatively, you can send an email with answers to the last three questions in the form and I will try my best to respond to you.
            </Text>
            <Text color={"white.600"} fontSize={"xs"} px={4}>
              The best way to reach me is through email!
                          </Text>
 
            <Center>
              
              <HStack pt={4} spacing={4}>
                <FaLinkedin onClick={linkedin} size={28} />
                <FaEnvelope onClick={email} size={28} />
              </HStack>
            </Center>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

